// src/components/LoginModal.jsx

import React from 'react';
import { ModalBackground, ModalContent, Button } from "./StyledComponents";

const LoginModal = ({ isOpen, close, onAuthenticate }) => {
  const handleAuthenticate = () => {
    // Simulate an API call with a delay
    setTimeout(() => {
      // This would be the actual username returned from Instagram
      onAuthenticate('mock_handle');
    }, 1000);
  };

  if (!isOpen) return null;

  return (
    <ModalBackground onClick={close}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <h2>Instagram Authentication Required</h2>
        <p>
          To ensure the authenticity of reviews, we require you to confirm that you own the Instagram username. We only use this information for authentication purposes and to verify the identity of the reviewer. If you're claiming a username, you will be able to respond to reviews.
        </p>
        <Button onClick={handleAuthenticate}>
          Authenticate with Instagram
        </Button>
      </ModalContent>
    </ModalBackground>
  );
};

export default LoginModal;
