// src/components/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: mistyrose; /* Same as page background */
  padding: 20px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #ddd; /* Subtle border */
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
`;

const FooterLink = styled(Link)`
  margin: 0 15px;
  text-decoration: none;
  color: #555; /* Less attention-grabbing color */
  font-size: 0.8rem; /* Smaller text */
`;

const Footer = ({ extraLinks = [] }) => {
  return (
    <FooterContainer>
      <FooterLink to="/contact">Contact</FooterLink>
      <FooterLink to="/terms-and-conditions">Terms and Conditions</FooterLink>
      {extraLinks.map((link, index) => (
        <FooterLink key={index} to={link.path}>
          {link.label}
        </FooterLink>
      ))}
    </FooterContainer>
  );
};

export default Footer;
