import styled from 'styled-components';
import { Link } from 'react-router-dom'; 

export const UsernameAndReview = styled.div``


export const SubHeading = styled.h2`
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 40px;
  font-style: italic;
  color: #444; // or any color that you want

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;


export const WarningText = styled.p`
color: grey; // Tomatolight red color.
font-size: .8rem;
font-weight: 500; // Instead of bold, a less heavy font.
text-align: center;
margin-top: 1rem;
margin-bottom: 1rem;
`;

export const ReviewsContainer = styled.div`
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 100%;
  max-width: 900px;
  margin: auto;
  align-items: center;
`;

export const StarRating = styled.div`
  direction: rtl; 
  font-size: large;
  z-index:1;

  > input {
    display: none; 
  }

  > label {
    font-size: 30px;
    color: grey;
    padding: 10px;
    cursor: pointer;
  }

  > input:checked ~ label {
    color: gold;
  }

  > label:hover,
  > label:hover ~ label {
    color: gold;
  }
`;




export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: 100%;
  max-width: 900px;
  margin: auto;
  justify-content: center;
  color: black;
  margin-bottom:100px;
`;

export const EmptyState = styled.div`
  text-align: center;
  color: black;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50vh;
`;

export const EmptyStateHeader = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem; 
`;

export const EmptyStateBody = styled.p`
  font-size: 1.2rem;
`;

export const Review = styled.div`
  width: 100%; 
  min-height: 200px; 
  max-height: 400px;
  overflow: auto; 
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #ffffff33;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  padding: 1rem;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ReviewContent = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  color: black;
  margin-bottom: 1rem; 
  text-align: justify; 
`;

export const ReviewAuthor = styled.p`
  font-size: 1rem;
  color: black;
  align-self: flex-end; 
`;

export const Heading = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 50px;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;


export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0; // add this to start positioning from the left edge of the page
  width: 100%;
  display: flex;
  justify-content: center; // instead of space-evenly to center the button
  background-color: #ffffff33;
  z-index:10;
  padding: 0; // add this if you have default padding which may offset the alignment
  flex-wrap: wrap; // Allows the button to wrap if they can't fit
  @media (max-width: 768px) {
    justify-content: space-between; // Spread the buttons across the width
  }
`;


export const StickyButton = styled.button`
  margin: 1rem;
  padding: 1.5rem 3rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #333;
  cursor: pointer;
  position: sticky;
  bottom: 0;
  //width: 100%; Remove or adjust this
  z-index: 10;

  &:hover {
    background-color: #777;
  }

  @media (max-width: 768px) {
    height: 70px;
    padding: 0.5rem 1rem; // Adjust padding to reduce button width
    font-size: 0.8rem; // Reduce font-size for smaller screens
    flex-grow: 1; // Make button take up equal space in the container
  }
`;


export const UserName = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style:italic;
  text-align:center;
`;

export const LinkButton = styled(Link)`
  font-size: 0.8rem;
  text-decoration: underline;
  color: black;
  font-style:normal;

`;



export const UserNameLink = styled.a`
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Logo = styled.img`
  width: 50px; // adjust to suit your logo size
`;

export const SiteTitle = styled.h1`
  font-size: 2rem; // adjust to suit your design
`;

export const ReplyButton = styled.button`
  margin: 0.5rem 0; // adjusted to create a full-width effect
  padding: 1rem; // increased padding for larger button
  font-size: 1.2rem; // increase font size
  border: none;
  border-radius: 5px;
  color: black;
  background-color: #333;
  width: 100%; // make it full width
  text-align: center; // center the button text
  cursor: pointer;

  &:hover {
    background-color: #777;
  }
`;
export const UserItemPlaceholder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 0.5rem 0;

  &:last-child {
    border-bottom: none;
  }

  &:before {
    content: 'Loading...';
    flex-grow: 1;
    text-align: center;
  }
`;



export const ModalStyles = styled.div`
  background: white;
  padding: 2em;
  border-radius: 8px;
  max-width: 500px;
  width: 80%;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
`;



export const UserItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 0.5rem 0;

  &:last-child {
    border-bottom: none;
  }
`;



export const UserRating = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 0.5rem;
  }
`;

export const Star = styled.div`
  color: gold;
  margin-right: 0.25rem;
`;


export const HomePageContainer = styled.div`
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: mistyrose;
  color: black;
  padding: 20px;
`;


export const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom:100px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Column = styled.div`
  flex: 1;
  margin: 1rem;
  padding: 1rem;
  background: #ffffff33;
  border-radius: 10px;
`;

export const ListItem = styled.div`
  margin: 1rem 0;
  font-size: 1.2rem;
`;


export const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 1rem 0;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 16px;
  font-family: Arial, sans-serif; // Use your preferred font family
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin: 1rem 0;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 16px;
  font-family: Arial, sans-serif; // Use your preferred font family
`;





export const Button = styled.button`
  margin: 0.5rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #333;
  cursor: pointer;
  min-height: 44px;
  min-width: 44px;
  &:hover {
    background-color: #777;
  }
`;



export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 2em;
  border-radius: 8px;
  max-width: 500px;
  width: 80%;
  text-align: center;
  color: #000;
`;

