import React from 'react';
import styled from 'styled-components';

const TermsContainer = styled.div`
  padding: 2rem;
  line-height: 1.6;
`;

const Heading = styled.h1`
  text-align: center;
  margin-bottom: 2rem;
`;

const SubHeading = styled.h2`
  margin-top: 1.5rem;
`;

const TermsAndConditions = () => {
  return (
    <TermsContainer>
      <Heading>Terms and Conditions</Heading>
      
      <SubHeading>1. Introduction</SubHeading>
      <p>
        Welcome to UGCRank. These terms and conditions outline the rules and regulations for the use of UGCRank's Website. By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use UGCRank's website if you do not accept all the terms and conditions stated on this page.
      </p>

      <SubHeading>2. License</SubHeading>
      <p>
        Unless otherwise stated, UGCRank owns the intellectual property rights for all material on UGCRank. All intellectual property rights are reserved. You may view and/or print pages from UGCRank.com for your own personal use subject to restrictions set in these terms and conditions.
      </p>
      
      <SubHeading>3. User Comments and Reviews</SubHeading>
      <p>
        Certain parts of this website offer the opportunity for users to post reviews, comments, and information in areas of the website. UGCRank does not screen, edit, publish or review comments prior to their appearance on the website and comments do not reflect the views or opinions of UGCRank, its agents or affiliates. Comments reflect the view and opinion of the person who posts such view or opinion. UGCRank does not verify the identity of any users contributing to these areas. Users may post under pseudonyms and any resemblance to actual persons, living or dead, or actual events is purely coincidental.
      </p>

      <SubHeading>4. Hyperlinking to our Content</SubHeading>
      <p>
        The following organizations may link to our Web site without prior written approval: Government agencies, Search engines, News organizations, Online directory distributors when they list us in the directory may link to our Web site in the same manner as they hyperlink to the Websites of other listed businesses, and Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site. These organizations may link to our home page, to publications or to other Web site information so long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.
      </p>

      <SubHeading>5. Content Liability</SubHeading>
      <p>
        We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify and defend us against all claims arising out of or based upon your Website.
      </p>

      <SubHeading>6. Governing Law</SubHeading>
      <p>
        Any claim related to UGCRank's Website shall be governed by the laws of USA without regard to its conflict of law provisions.
      </p>

      <SubHeading>Contact Us</SubHeading>
      <p>
        If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:info@ugcrank.com">info@ugcrank.com</a>.
      </p>
    </TermsContainer>
  );
};

export default TermsAndConditions;
