import React from "react";
import { HomePageContainer, Review, ReviewContent, StickyButton } from "../../components/StyledComponents";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
    const navigate = useNavigate();

  const handleHomeClick = () => {
   navigate('/');
  };

  return (
    <HomePageContainer>
      <Review>
        <ReviewContent>
          <h1>Privacy Policy</h1>
          <h2>1. Introduction</h2>
          <p>Welcome to UGCRank. This privacy policy outlines how we collect, use, and protect your personal information when you use our website.</p>
          <h2>2. Personal Data Collection</h2>
          <p>While using UGCRank, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. This may include, but is not limited to, your email address and name. We collect this information for the purpose of providing the Service, identifying and communicating with you, responding to your requests/inquiries, servicing your purchase orders, and improving our services.</p>
          <h2>3. Log Data</h2>
          <p>We may also collect information that your browser sends whenever you visit our Service ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>
          <h2>4. Cookies</h2>
          <p>Cookies are files with a small amount of data, which may include an anonymous unique identifier. We use "cookies" to collect information in order to improve our services for you. You have the option to either accept or refuse these cookies. If you choose to refuse our cookies, you may not be able to use some portions of our Service.</p>
          <h2>5. Service Providers</h2>
          <p>We may employ third-party companies and individuals due to the following reasons: to facilitate our Service; to provide the Service on our behalf; to perform Service-related services; or to assist us in analyzing how our Service is used. These third parties have access to your Personal Information in order to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
          <h2>6. Security</h2>
          <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
          <h2>7. Links to Other Sites</h2>
          <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
          <h2>8. Changes to This Privacy Policy</h2>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
          <h2>9. Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at info@ugcrank.com.</p>
        </ReviewContent>
      </Review>
      <StickyButton onClick={handleHomeClick}>Home</StickyButton>
    </HomePageContainer>
  );
};

export default PrivacyPolicy;
