import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

export const HomePageContainer = styled.div`
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: mistyrose;
  color: black;
  padding: 20px;
`;

export const StyledMessage = styled.p`
  font-size: 1.5rem;
  line-height: 1.5;
  color: black;
  margin-bottom: 1rem;
  text-align: center; // align the text to center
`;

export const StickyButton = styled.button`
  margin: 1rem;
  padding: 1.5rem 3rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #333;
  cursor: pointer;
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 10;
  &:hover {
    background-color: #777;
  }
  height:100px;
`;

const VerifyReview = () => {
  const [status, setStatus] = useState("Verifying...");
  const navigate = useNavigate();

  // Extract the token from the query parameters
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const token = query.get('token');

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/verifyreview`, { token })
      .then((response) => {
        setStatus(response.data.message);
      })
      .catch((error) => {
        if (error.response) {
          setStatus(error.response.data.error);
        } else {
          setStatus("Network Error. Please try again.");
        }
      });
  }, [token]);

  return (
    <HomePageContainer>
      <StyledMessage>{status}</StyledMessage>
      <StickyButton onClick={() => navigate('/')}>Search Creators, Brands, And Agencies</StickyButton>
    </HomePageContainer>
  );
};

export default VerifyReview;
