// src/pages/HomePage.jsx
import React, { useState, useEffect } from 'react';
import SearchBar from '../components/SearchBar';
import { HomePageContainer, Heading, Section, Column, ListItem, SubHeading } from '../components/StyledComponents';
import { UserItem, UserNameLink, UserRating, Star, UserItemPlaceholder, LogoContainer, SiteTitle, Logo } from '../components/StyledComponents';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import logo from '../assets/logo.png'; // replace with your logo file path

const HomePage = () => {
  const [topCreators, setTopCreators] = useState([
    { username: 'tran.ugc', rating: 5.0, reviews: 200 },
    { username: 'danirkilgore', rating: 5.0, reviews: 150 },
    { username: 'brianna.ali', rating: 5.0, reviews: 500 },
    { username: 'teddiwright', rating: 5.0, reviews: 300 },
    { username: 'graacechang', rating: 5.0, reviews: 100 }

  ]);

  const [topBusinesses, setTopBusinesses] = useState([
    { username: 'jolieskinco', rating: 5.0, reviews: 250 },
    { username: 'theselfcareshower', rating: 5.0, reviews: 120 },
    { username: 'pillowcube', rating: 5.0, reviews: 400 },
    { username: 'hannahrosejewelry', rating: 5.0, reviews: 200 },
    { username: 'bigblanketco', rating: 5.0, reviews: 90 }
  ]);

  const [topAgencies, setTopAgencies] = useState([
    { username: 'rba.dtx', rating: 5.0, reviews: 250 },
    { username: 'tbwasg', rating: 5.0, reviews: 120 },
    { username: 'palocreative', rating: 5.0, reviews: 400 },
    { username: 'slicedbread.agency', rating: 5.0, reviews: 200 },
    { username: 'cakecommerceagency', rating: 5.0, reviews: 90 }
  ]);



  const handleSearch = (searchTerm) => {
    console.log(`Searching for ${searchTerm}`);
  };
  return (
    <HomePageContainer>
      <LogoContainer>
        <Logo src={logo} alt="UGCRank logo" />
        <SiteTitle>UGCRank.com</SiteTitle>
      </LogoContainer>
      <Heading>See creator, brand, and agency reviews - before you work with them.</Heading>
      <SubHeading>Like Rotten Tomatoes for brands and creators</SubHeading>

      <SearchBar onSearch={handleSearch} />
      <Section>
        <Column>
          <h2 style={{fontSize:"20px", whiteSpace:"nowrap"}}>Featured Creators</h2>
          {(topCreators ? topCreators : Array(5).fill(0)).map((creator, index) => (
            creator ? (
              <UserItem key={index}>
                <UserNameLink as={Link} to={`/reviews/${creator.username}`}>{creator.username}</UserNameLink>
                <UserRating>
                  <Star>⭐</Star>{creator.rating} {/*<span>({creator.reviews})</span> */}
                </UserRating>
              </UserItem>
            ) : <UserItemPlaceholder key={index} />
          ))}
        </Column>
        <Column>
          <h2 style={{fontSize:"20px",whiteSpace:"nowrap"}}>Featured Brands</h2>
          {(topBusinesses ? topBusinesses : Array(5).fill(0)).map((business, index) => (
            business ? (
              <UserItem key={index}>
                <UserNameLink as={Link} to={`/reviews/${business.username}`}>{business.username}</UserNameLink>
                <UserRating>
                 <Star>⭐</Star>{business.rating} {/* <span>({business.reviews})</span> */}
                </UserRating>
              </UserItem>
            ) : <UserItemPlaceholder key={index} />
          ))}
        </Column>
        <Column>
          <h2 style={{fontSize:"20px",whiteSpace:"nowrap"}}>Featured Agencies</h2>
          {(topAgencies ? topAgencies : Array(5).fill(0)).map((business, index) => (
            business ? (
              <UserItem key={index}>
                <UserNameLink as={Link} to={`/reviews/${business.username}`}>{business.username}</UserNameLink>
                <UserRating>
                 <Star>⭐</Star>{business.rating} {/* <span>({business.reviews})</span> */}
                </UserRating>
              </UserItem>
            ) : <UserItemPlaceholder key={index} />
          ))}
        </Column>
      </Section>
      <Footer extraLinks={[{ path: "/privacy", label: "Privacy Policy" }]} />

    </HomePageContainer>
    
  );
};

export default HomePage;


