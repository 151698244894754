// /src/components/ReplyModal.js

import React, { useState } from 'react';
import { ModalBackground, ModalContent, Button, TextArea } from './StyledComponents';

const ReplyModal = ({ isOpen, close, onReply }) => {
  const [replyText, setReplyText] = useState('');

  const handleSubmitReply = () => {
    onReply(replyText);
    setReplyText('');
    close();
  };

  if (!isOpen) return null;

  return (
    <ModalBackground onClick={close}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <h2>Reply to a Review</h2>
        <p>Please write your reply below:</p>
        <TextArea
          value={replyText}
          onChange={e => setReplyText(e.target.value)}
          placeholder="Your Reply"
        />
        <Button onClick={handleSubmitReply}>Submit Reply</Button>
      </ModalContent>
    </ModalBackground>
  );
};

export default ReplyModal;
