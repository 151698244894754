// src/pages/ReviewsPage.jsx

import axios from 'axios'; // Assuming you have installed axios
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import LoginModal from '../components/LoginModal';
import LeaveReviewModal from '../components/LeaveReviewModal';
import ReplyModal from '../components/ReplyModal';
import { UsernameAndReview, ButtonContainer, ReviewsContainer, PageContainer, UserName, EmptyState, EmptyStateBody, EmptyStateHeader, Review, StickyButton, LinkButton, ReviewContent, ReviewAuthor, ReplyButton } from '../components/StyledComponents';
import StarRatings from 'react-star-ratings';
import ShareModal from '../components/ShareModal'; // Add this import to the top
import logo from "../assets/logo.png"
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';
import { FaShareSquare } from 'react-icons/fa';



function ReviewsPage({ currentUser, onAuthenticate }) {
    const [currentReviewId, setCurrentReviewId] = useState(null);
    const [replyText, setReplyText] = useState('');
    const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);
    const { username } = useParams();
    const role = !currentUser ? 'public' : (currentUser === username ? 'owner' : 'reviewer');
    const [reviews, setReviews] = useState([]);
    const [score, setScore] = useState(0);
    const [numberOfReviews, setNumberOfReviews] = useState(0);
    const navigate = useNavigate();
    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
    const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [profileLink, setProfileLink] = useState('');
    const usernameRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);  // New piece of state to track loading status



    const handleShareProfile = () => {
        const profileLink = `${window.location.origin}/reviews/${username}`;
        navigator.clipboard.writeText(profileLink).then(() => {
            setProfileLink(profileLink);
            setIsShareModalOpen(true);
        });
    };

    const handleGoHome = () => navigate('/'); // This will navigate to the home page.

    const handleAuthenticate = username => {
        onAuthenticate(username);
        setIsAuthModalOpen(false);
    };

    const handleShareReview = async (reviewId) => {
        const node = document.getElementById(reviewId);

        try {
            const canvas = await html2canvas(node);
            const dataUrl = canvas.toDataURL();
            const link = document.createElement('a');
            link.download = 'review.png';
            link.href = dataUrl;
            link.click();

            toast.success('Review image has been downloaded!', {
                position: toast.POSITION.BOTTOM_CENTER
            });
        } catch (error) {
            console.error("Failed to convert review to image:", error);

            toast.error('Failed to download review image. Please try again.', {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    };

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/reviews/${username}`);
                console.log("reviews")
                console.log(response.data)
                setReviews(response.data.reviews);
                setScore(response.data.score);
                setNumberOfReviews(response.data.numberOfReviews);
                setIsLoading(false);  // Set loading status to false once data has been fetched
            } catch (error) {
                console.error("Error fetching reviews:", error);
                setIsLoading(false);  // Also set loading status to false in case of error
            }
        };
        fetchReviews();
    }, [username]);

    // Other handlers

    const handleLeaveReview = async review => {
        const newReview = {
            text: review.text,
            author: review.handle,
            email: review.email,
            username,
            rating: review.rating,
            isReply: false
        };
        console.log("submitting review")
        console.log(newReview)
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/reviews`, newReview);
            setReviews(prevReviews => [response.data, ...prevReviews]);

            // Update the score and number of reviews
            setScore(prevScore => prevScore + review.rating);
            setNumberOfReviews(prevNumberOfReviews => prevNumberOfReviews + 1);
        } catch (error) {
            console.error("Error posting review:", error);
        }
    };




    const handleClaimUsername = () => setIsAuthModalOpen(true);


    const handleReplySubmit = async (replyText) => {
        const newReply = {
            reviewId: currentReviewId,
            text: replyText,
            username: currentUser,
            isReply: true
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/reviews`, newReply);
            setReviews(prevReviews => prevReviews.map(review => {
                if (review.reviewId === currentReviewId) {
                    return response.data;
                } else {
                    return review;
                }
            }));
            setIsReplyModalOpen(false);
            setCurrentReviewId(null);
        } catch (error) {
            console.error("Error posting reply:", error);
        }
    };



    return (
        <PageContainer>
            <UserName>
                <UsernameAndReview ref={usernameRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <a href={`https://www.instagram.com/${username}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div>@{username}</div>
                    </a>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <img src={logo} alt="logo" style={{ height: '20px', marginRight: '5px' }} />
                        <span style={{ fontSize: '14px', fontWeight: 'normal' }}>UGCRank.com</span>
                    </div>
                    <div style={{ marginTop: '-10px' }}> {/* Adjust this value as per your requirement */}
                        <StarRatings
                            rating={numberOfReviews ? score / numberOfReviews : 0} // Calculate average score
                            numberOfStars={5}
                            starDimension="20px"
                            starSpacing="2px"
                            starRatedColor="gold"
                        />
                        <span style={{ fontSize: '14px', fontWeight: 'normal', marginLeft: "5px" }}>{"("}{numberOfReviews}{")"}</span>

                    </div>

                </UsernameAndReview>
                {/* {role === 'public' && <LinkButton onClick={handleClaimUsername} style={{ alignSelf: 'center', marginTop: '10px' }}>I Own This Username</LinkButton>} */}
            </UserName>














            <LoginModal isOpen={isAuthModalOpen} close={() => setIsAuthModalOpen(false)} onAuthenticate={handleAuthenticate} />

            <LeaveReviewModal isOpen={isReviewModalOpen} close={() => setIsReviewModalOpen(false)} onLeaveReview={handleLeaveReview} />
            <ReplyModal isOpen={isReplyModalOpen} close={() => setIsReplyModalOpen(false)} onReply={handleReplySubmit} />


            {isLoading
                ? <p style={{textAlign: "center"}}>Loading...</p>  // If isLoading is true, display a loading message
                : reviews.length === 0
                    ? <EmptyState>
                        <EmptyStateHeader>No reviews yet</EmptyStateHeader>
                        <EmptyStateBody>
                            Be the first to review @{username}! Click the "Leave Review" button below.
                        </EmptyStateBody>
                    </EmptyState>
                    : <ReviewsContainer>
                        {reviews.map(review => (
                            <Review id={review.reviewId} key={review.reviewId}>
                                <div style={{ width: '100%' }}>
                                    <div style={{ float: "left",fontSize:"10px", color:"grey"  }} >
                                      {!review.isVerified && <small>Pending Verification</small>}
                                    </div>
                                    <div style={{ float: "right"}} >
                                        UGCRank.com - <FaShareSquare onClick={() => handleShareReview(review.reviewId)} />
                                    </div>

                                    {/* <button  onClick={() => handleShareReview(review.reviewId)}>
                                  <img src="/path/to/share-icon.png" alt="Share" />  
                              </button> */}
                                </div>
                                <ReviewContent>{'"'}{review.text}{'"'}</ReviewContent>
                                <ReviewAuthor>{"@"}{review.author}</ReviewAuthor>
                                <StarRatings
                                    rating={review.rating}
                                    numberOfStars={5}
                                    name='rating'
                                    starDimension="20px"
                                    starSpacing="2px"
                                    starRatedColor="gold"
                                />

                                {role === 'owner' && (
                                    <div style={{ width: '100%' }}>
                                        {!review.replies.length && (
                                            <ReplyButton onClick={() => {
                                                setCurrentReviewId(review.reviewId);
                                                setIsReplyModalOpen(true);
                                            }}>Reply</ReplyButton>
                                        )}
                                    </div>
                                )}


                                {review.replies && review.replies.map(reply => (
                                    <p key={reply.id}>{reply.text} {"- @"}{reply.author}</p>
                                ))}
                            </Review>
                        ))}
                    </ReviewsContainer>
            }

            <ButtonContainer>
                <StickyButton onClick={handleGoHome}>&#x21A9; Search</StickyButton>

                <StickyButton onClick={handleShareProfile}>Share Profile</StickyButton>
                {(role === 'public' || role === 'reviewer') && (
                    <StickyButton onClick={() => setIsReviewModalOpen(true)}>Write Review</StickyButton>
                )}
            </ButtonContainer>
            <ShareModal isOpen={isShareModalOpen} close={() => setIsShareModalOpen(false)} profileLink={profileLink} usernameRef={usernameRef} />

        </PageContainer>
    );
}


export default ReviewsPage;
