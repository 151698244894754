// src/App.js

import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReviewsPage from './pages/ReviewsPage';
import HomePage from './pages/Homepage';
import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import VerifyReview from "./pages/VerifyReview";
import ContactPage from './pages/information/Contact';
import TermsAndConditions from './pages/information/Terms';
import PrivacyPolicy from './pages/information/Privacy';
import { createGlobalStyle } from 'styled-components';
import { clarity } from 'clarity-js';
import ReactGA from "react-ga4";

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }
  /* other global styles... */
`;

function App() {
  const [currentUser, setCurrentUser] = useState(null);

  const handleAuthenticate = (username) => {
    setCurrentUser(username);
  };

  useEffect(() => {
    clarity.consent()

    clarity.start({
      projectId: 'i3dw22lr0g',
      upload: 'https://m.clarity.ms/collect',
      track: true,
      content: true,
      cookies: []
    })


    return () => {
      clarity.stop()
    }
  }, [])

  return (
    <>
      <ToastContainer />
      <GlobalStyle />
      <Router>
        <RoutesComponent currentUser={currentUser} onAuthenticate={handleAuthenticate} />
      </Router>
    </>
  );
}

function RoutesComponent({ currentUser, onAuthenticate }) {
  const location = useLocation();

  useEffect(() => {
    async function initializeAnalytics() {
      await ReactGA.initialize("S1BX3PB3KG");
      ReactGA.send({ hitType: "pageview", page: location.pathname });

    }

    initializeAnalytics();
  }, [location]);

  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/reviews/:username' element={<ReviewsPage currentUser={currentUser} onAuthenticate={onAuthenticate} />} />
      <Route path="/verifyreview" element={<VerifyReview />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
    </Routes>
  );
}

export default App;
