import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    height: 80vh; // take up most of the viewport
    background-color: #ffffff33;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
`;

const Title = styled.h1`
    font-size: 2.5rem;
    color: black;
    text-align: center;
    margin-bottom: 2rem;
`;

const EmailLink = styled.a`
    font-size: 1.5rem;
    color: #333;
    text-decoration: none;
    &:hover {
        color: #777;
    }
`;

const HomeLink = styled(Link)`
  display: inline-block;
  margin: 1rem;
  padding: 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #333;
  text-decoration: none;
  text-align: center;
  &:hover {
    background-color: #777;
  }
`;

const ContactPage = () => {
    return (
        <ContactContainer>
            <Title>Contact Us</Title>
            <EmailLink href="mailto:info@ugcrank.com">info@ugcrank.com</EmailLink>
            <HomeLink to="/">Home</HomeLink>
        </ContactContainer>
    );
};

export default ContactPage;
