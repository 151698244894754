// src/components/SearchBar.jsx

import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import styled, { css } from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import { BeatLoader } from "react-spinners";
import { useNavigate } from 'react-router-dom';

// Reusable styles for the input field and search button

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
`;

const InputField = styled.input`
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 25px;
  padding: 10px 40px 10px 20px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #333;
  transition: all 0.3s ease;
  outline: none;

  // Larger devices
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }

  &:focus {
    box-shadow: 5 5 20px #aaa;
  }

  ${props =>
    props.loading &&
    css`
      padding-right: 60px;
    `}
`;

const SearchButton = styled(FaSearch)`
  position: absolute;
  right: 15px;
  font-size: 1.2rem;
  color: #fff;

  // Larger devices
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  right: 15px;
`;

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSearch = () => {
    if (!searchTerm) return;

    setLoading(true);
    onSearch(searchTerm);

    setTimeout(() => {
      setLoading(false);
      navigate('/reviews/' + searchTerm);
    }, 200);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleChange = (event) => {
    // The regular expression for the allowed characters
    const regex = /^[a-zA-Z0-9_.]*$/;
    
    // Only update the search term if it's valid
    if (regex.test(event.target.value)) {
      setSearchTerm(event.target.value.toLowerCase());
    }
  };

  return (
    <SearchBarContainer>
      <InputField
        type="text"
        value={searchTerm}
        onChange={handleChange}
        onKeyUp={handleKeyPress}
        placeholder="Search Instagram Handle"
        loading={loading}
        maxLength={30}
      />
      {loading ? (
        <SpinnerContainer>
          <BeatLoader size={15} color="#fff" />
        </SpinnerContainer>
      ) : (
        <SearchButton onClick={handleSearch} />
      )}
    </SearchBarContainer>
  );
};

export default SearchBar;


