import React from 'react';
import { ModalBackground, ModalContent, Button } from './StyledComponents';
import html2canvas from 'html2canvas';
import { FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const ShareModal = ({ isOpen, close, profileLink, usernameRef }) => {
    const handleGenerateImage = () => {
        if (usernameRef.current) {
            html2canvas(usernameRef.current).then(function (canvas) {
                let img = canvas.toDataURL();
                // For now, we just download the image. You might want to handle it differently in your app.
                let link = document.createElement('a');
                link.download = 'UGCRank.comSharingImage.png';
                link.href = img;
                link.click();
            });
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(profileLink);
        toast.success('Copied to clipboard!');
    };

    if (!isOpen) return null;

    return (
        <ModalBackground onClick={close}>
            <ModalContent onClick={e => e.stopPropagation()}>
                <h2>Share Your Profile</h2>
                <p style={{ color: "green" }}>Successfully copied your profile link:</p>
                <p style={{ border: '1px solid black', padding: '10px', wordWrap: 'break-word' }}>
                    {profileLink}
                    <button onClick={copyToClipboard} style={{ marginLeft: '10px', background: 'none', border: 'none' }}>
                        <FaCopy />
                    </button>
                </p>

                <Button style={{ color: "white" }} onClick={handleGenerateImage}>Download My Rating (For Canva, Sharing, Etc)</Button>
            </ModalContent>
        </ModalBackground>
    );
};

export default ShareModal;
