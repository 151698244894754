// src/components/LeaveReviewModal.jsx
import React, { useState, useEffect } from 'react';
import { ModalBackground, ModalContent, Button, TextArea, Input, WarningText } from './StyledComponents';
import StarRatings from 'react-star-ratings';
import {toast} from 'react-toastify'

const LeaveReviewModal = ({ isOpen, close, onLeaveReview }) => {
  const [reviewText, setReviewText] = useState('');
  const [instagramHandle, setInstagramHandle] = useState('');
  const [email, setEmail] = useState('');
  const [rating, setRating] = useState(4);
  const [starDimension, setStarDimension] = useState("40px");
  const [starSpacing, setStarSpacing] = useState("7px");

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setStarDimension("20px");
        setStarSpacing("3px");
      } else {
        setStarDimension("40px");
        setStarSpacing("7px");
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // call the function initially to check window size

    // cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

    const handleInstagramHandleChange = (e) => {
    // The regular expression for the allowed characters
    const regex = /^[a-zA-Z0-9_.]*$/;
    // Only update the instagramHandle if it's valid and less than 31 characters
    if (regex.test(e.target.value) && e.target.value.length <= 30) {
      setInstagramHandle(e.target.value.toLowerCase());
    }
  };

  const handleSubmitReview = () => {
    if (reviewText === '' || instagramHandle === '' || email === '') {
      toast.error('All fields must be filled in!', {
        position: "bottom-center",
      });
      return;
    }
  
    // Instagram handle validation regex
    const handlePattern = /^[a-zA-Z0-9_.]{1,30}$/;
  
    if (!handlePattern.test(instagramHandle)) {
      toast.error('Please enter a valid Instagram handle!', {
        position: "bottom-center",
      });
      return;
    }
  
    // Email validation regex
    const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  
    if (!emailPattern.test(email)) {
      toast.error('Please enter a valid email address!', {
        position: "bottom-center",
      });
      return;
    }
  
    onLeaveReview({ text: reviewText, handle: instagramHandle, email: email, rating: rating });
    setReviewText('');
    setInstagramHandle('');
    setEmail('');
    setRating(4);
    close();
    toast.success("Check your email to verify your review");
  };
  

  if (!isOpen) return null;

  return (
    <ModalBackground onClick={close}>
  <ModalContent onClick={e => e.stopPropagation()} role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
    <h2 id="modalTitle">Write Review</h2>
    <p id="modalDescription">Please write your review below:</p>

    <Input
      type="text"
      value={instagramHandle}
      onChange={handleInstagramHandleChange}
      placeholder="Your Instagram Handle"
      aria-label="Instagram Handle"
      maxLength={30}
    />
    <Input
      type="email"
      value={email}
      onChange={e => setEmail(e.target.value)}
      placeholder="Email Used To Verify Review"
      aria-label="Email Address"
      autoComplete="email"
    />
    <StarRatings
      rating={rating}
      starRatedColor="gold"
      starHoverColor="gold"
      changeRating={setRating}
      numberOfStars={5}
      name='rating'
      starDimension={starDimension}
      starSpacing={starSpacing}
      aria-label="Rating"
    />

    <TextArea
      value={reviewText}
      onChange={e => setReviewText(e.target.value)}
      placeholder="Your Review"
      aria-label="Review Text"
    />
    <WarningText>Reviews cannot be edited-Double check!</WarningText>
    <Button onClick={handleSubmitReview} aria-label="Submit Review">Submit Review</Button>
  </ModalContent>
</ModalBackground>

  );
};

export default LeaveReviewModal;
